<template>
  <div class="banks-trash fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="bankTrashList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @bank:restore="restoreBank($event)"
                             @bank:deleted="$refs.deleteConfirmation.showDialog(), bankDataForDelete = $event"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getBanksTrash()"/>

    <custom-dialog ref="deleteConfirmation"
                   :title="`${$t('treasury.banks.trash.confirmations.delete.title')}`"
                   :body="`${$t('treasury.banks.trash.confirmations.delete.body', {bank: bankDataForDelete.name})}`"
                   @accept="deleteBank(bankDataForDelete)"/>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import CustomDialog from '@/components/customDialog/customDialog'
import {checkUserPermissions} from '@/assets/js/functions'
import {deleteBank, getBanksTrash, restoreBank} from '@/http/requests/treasury/banks'
import axios from 'axios'

export default {
  name: 'banksTrash',
  components: {
    CustomDialog,
    DraggableDynamicTable
  },
  metaInfo () {
    return {
      title: this.$t('treasury.banks.trash.title')
    }
  },
  data () {
    return {
      options: {
        id: 'usersTrashList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'storeAction',
          i18n: 'treasury.banks.table.header.restore',
          color: 'success',
          width: '70px',
          minWidth: 70,
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'bank:restore',
          action: {
            icon: 'REFRESH',
            iconPack: 'useral',
            color: 'success',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'deleteAction',
          i18n: 'treasury.banks.table.header.delete',
          color: 'danger',
          width: '70px',
          minWidth: 70,
          align: 'center',
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'bank:deleted',
          action: {
            icon: 'TRASH',
            iconPack: 'useral',
            color: 'danger',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'balance',
          i18n: 'treasury.banks.table.header.balance',
          width: 'calc(100% / 4)',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'account_number',
          i18n: 'treasury.banks.table.header.accountNumber',
          width: 'calc(100% / 4)',
          minWidth: 170,
          align: 'center',
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'card_number',
          i18n: 'treasury.banks.table.header.cardNumber',
          width: 'calc(100% / 4)',
          minWidth: 170,
          align: 'center',
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'treasury.banks.table.header.name',
          width: 'calc(100% / 4)',
          minWidth: 170,
          sortable: true,
          locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.banks.table.header.row',
          width: '60px',
          minWidth: 60,
          align: 'center',
          // sortable: true,
          locked: true,
          footer: {}
        }
      ],
      data: [],
      page: 1,
      filters: [],
      sorts: [],
      endedList: false,
      actions: {
        list: [
          {
            id: 'printTable',
            i18n: 'draggableDynamicTable.actions.print',
            icon: 'PRINT',
            iconPack: 'useral'
          },
          {
            id: 'downloadTable',
            i18n: 'draggableDynamicTable.actions.download',
            icon: 'DOWNLOAD',
            iconPack: 'useral'
          }
        ]
        // leftToolbar: [
        //   {
        //     id: {name: 'treasuryBanks'},
        //     type: 'link',
        //     icon: 'CHEVRON_LEFT',
        //     iconPack: 'useral'
        //   }
        // ]
      },
      bankDataForDelete: {}
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', '')
    }, 50)

    if (!checkUserPermissions('bank.forceDelete')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'deleteAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    if (!checkUserPermissions('bank.restore')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'storeAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    this.getBanksTrash()
  },
  methods: {
    getBanksTrash () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.bankTrashList && this.data.length === 0) this.$refs.bankTrashList.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.bankTrashList && this.data.length > 0) this.$refs.bankTrashList.loadMoreStatus = 'Loading'

          getBanksTrash(this.page, this.filters, this.sorts).then(response => {

            const banks = response.data.data
            banks.forEach((bank) => {

              this.data.push({
                row: this.data.length + 1,
                id: bank.id,
                name: bank.name,
                card_number: bank.card_number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-'),
                balance: {
                  type: 'price',
                  classes: bank.balance && bank.balance < 0 ? 'text-danger' : 'text-success',
                  value: bank.balance ? bank.balance : 0
                }
              })
            })
            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('rowNumber')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }

            if (this.$refs.bankTrashList) this.$refs.bankTrashList.loadMoreStatus = ''
          }).catch(error => {
            if (this.$refs.bankTrashList && !axios.isCancel(error)) this.$refs.bankTrashList.loadMoreStatus = 'Danger'
          })
        }
      }, 400)
    },
    setFilter (filters, remove) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'name':
          if (filters[key].search !== '') filters_list.push(`name=${  filters[key].search}`)
          break

        case 'account_number':
          if (filters[key].search !== '') filters_list.push(`account_number=${  filters[key].search}`)
          break

        case 'card_number':
          if (filters[key].search !== '') filters_list.push(`card_number=${  filters[key].search}`)
          break

        case 'balance':
          if (filters[key].search !== '') filters_list.push(`balance=${  filters[key].search  },${  filters[key].type.id}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getBanksTrash()
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

        case 'rowNumber':
          sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'account_number':
          sorts_list.push(`order[0]=account_number,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'card_number':
          sorts_list.push(`order[0]=card_number,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${  sorts[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getBanksTrash()
    },
    restoreBank (bank) {
      restoreBank(bank.id).then(res => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.banks.trash.notifications.restore.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.getBanksTrash()
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.banks.trash.notifications.restore.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    },
    deleteBank (bank) {
      deleteBank(bank.id, true).then(res => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.banks.trash.notifications.delete.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.getBanksTrash()
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          switch (error.response.status) {
            case 409:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('treasury.banks.trash.notifications.delete.isUsedError'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 5000
              })
              break

            default:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('treasury.banks.trash.notifications.delete.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
              break
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
